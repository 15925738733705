import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EntKey } from '../ws/model/ws.model';
import { EntData, SRV_ID, SrvResponse } from '../srv.types';
import { MockEntity } from './mock-constructors/entity';

/** Используем данный сервис для замены моковых данных в SrvService */
@Injectable({
    providedIn: 'root',
})
export class MockDataService {
    private mockData: { [key: string]: any } = {
        contract: {
            id: 'mock-contract-id',
            name: 'Mock Contract',
            // Add other properties as needed
        },
        // Add other entity types as needed
    };

    /** Возвращает имитированные данные как будто с сервера. Если есть id то сущность, если нет то список */
    public getMockData$(entityType: EntKey, id: SRV_ID): Observable<SrvResponse> {
        if (!id) return this.getList$(entityType);
        return this.getMockEntity$(entityType, id);
    }

    /** Возвращает обзервэбл имитирующий список сущностей */
    private getList$(entType: EntKey): Observable<SrvResponse> {
        const dataLength = 20;
        let result: SrvResponse = {
            data: [],
            links: null,
            meta: null,
        };
        for (let i = 0; i < dataLength; i++) {
            const item = new MockEntity({
                id: i.toString(),
                type: entType,
                attributes: {
                    // Стандартные поля для всех сущностей
                    dt_create: '2024-09-30T14:55:12.999637+03:00',
                    org_owner_inn: '7777777777',
                    org_owner_name: 'ТЕСТОВАЯ ОРГАНИЗАЦИЯ',
                    status_label: 'draft',
                    status_dt: '2024-09-30T14:55:12.998578+03:00',
                    __str__: 'Тестовый объект',
                },
                relationships: {},
            });
            (result.data as EntData[]).push(item);
        }
        console.log('🚀 ~ MockDataService ~ result:', result);
        return of(result);
    }

    /** Возвращает обзервэбл имитирующий одиночную сущность */
    getMockEntity$(entityType: EntKey, id: SRV_ID): Observable<SrvResponse> {
        const MOCK_ENTITY = { id, type: entityType, attributes: {}, relationships: {} };
        return of({ data: new MockEntity(MOCK_ENTITY) });
    }
}
